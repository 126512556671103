import React from 'react';
import { styled } from 'linaria/react';
import GatsbyImage from 'gatsby-image';
import { Partner } from '../hooks/usePartners';

export interface PartnerImageProps {
  partner: Partner;
  className?: string;
}

const PartnerImageInner = (props: PartnerImageProps) => {
  const { partner } = props;

  return (
    <a href={partner.link} target="_blank">
      <GatsbyImage
        fluid={partner.image}
        className={props.className}
        alt={'Partner Image'}
      />
    </a>
  );
};

export const PartnerImage = styled(PartnerImageInner)`
  width: 65%;
  margin: auto;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`;
