import PhoneIcon from '../../../../assets/icons/kma_form_icon_1.svg';
import PlaneIcon from '../../../../assets/icons/kma_form_icon_2.svg';
import MapIcon from '../../../../assets/icons/kma_form_icon_3.svg';

export const contactUsLinks = [
  {
    text: '+421 911 571 228',
    href: 'tel:+421903370897',
    icon: PhoneIcon
  },
  {
    text: 'info@kravmagawarrior.sk',
    href: 'mailto: info@kravmaga-warrior.sk',
    icon: PlaneIcon
  },
  {
    text: 'Základná škola Kuchyňa',
    href: 'https://maps.app.goo.gl/qEgX5wemg2uazZ7TA',
    icon: MapIcon
  },
  {
    text: 'Aircraft Sport House',
    href:
      'https://www.google.com/maps/place/Aircraft+Sport+House/@48.174888,17.190607,15z/data=!4m5!3m4!1s0x0:0x1616a13f19000b1f!8m2!3d48.174888!4d17.190607',
    icon: MapIcon
  },
  {
    text: 'MonkeyFit',
    href:
      'https://www.google.com/maps/place/Monkeyfit/@48.0895535,17.0674034,15z/data=!4m5!3m4!1s0x0:0x8990c56948ba7c2!8m2!3d48.0895535!4d17.0674034',
    icon: MapIcon
  }
];
