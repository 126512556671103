import { useStaticQuery, graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';

export interface Partner {
  link: string;
  image: FluidObject;
}

export const usePartners = (): Partner[] => {
  const {
    kajanGymImage,
    kravMagaGlobalImage,
    tcaImage,
    aircraftSportHouseImage
  } = useStaticQuery(graphql`
    query reviewImages {
      kravMagaGlobalImage: file(
        relativePath: { eq: "partner-logos/partner2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tcaImage: file(relativePath: { eq: "partner-logos/partner3.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aircraftSportHouseImage: file(
        relativePath: { eq: "partner-logos/partner4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return [
    {
      link: 'https://krav-maga.com/',
      image: kravMagaGlobalImage.childImageSharp.fluid
    },
    {
      link: 'https://www.tcacourses.co.uk/',
      image: tcaImage.childImageSharp.fluid
    },
    {
      link: 'https://www.aircraftsport.sk/',
      image: aircraftSportHouseImage.childImageSharp.fluid
    }
  ];
};
